/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { KTIcon } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      />
      
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div>
      <AsideMenuItem to='/RewardManagement' icon='crown' title='Reward Management' />
      <AsideMenuItem to='/LotteryManagement' icon='award' title='Lottery Management' />
      <AsideMenuItem to='/ParticipantManagement' icon='people' title='Parcitipant Management' />
      <AsideMenuItem to='/Report' icon='badge' title='Report' />
      <AsideMenuItem to='/UserManagement' icon='user' title='User Management' />
    </>
  )
}
