import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {

  const UsersPage = lazy(() => import('../pages/userManagement/UsersPage'))
  const RewardPage = lazy(() => import('../pages/RewardManagement/RewardPage'))
  const LotteryPage = lazy(() => import('../pages/LotterydManagement/LotteryPage'))
  const ParticipantPage = lazy(() => import('../pages/ParticipantManagement/ParticipantPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />

        <Route
          path='RewardManagement/*'
          element={
            <SuspensedView>
              <RewardPage />
            </SuspensedView>
          }
        />

        <Route
          path='LotteryManagement/*'
          element={
            <SuspensedView>
              <LotteryPage />
            </SuspensedView>
          }
        />

        <Route
          path='ParticipantManagement/*'
          element={
            <SuspensedView>
              <ParticipantPage />
            </SuspensedView>
          }
        />


        <Route
          path='userManagement/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
